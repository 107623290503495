import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Why is this blog made using Gatsby?`}</h1>
    <h2>{`Because TypeScript, React and GraphQL`}</h2>
    <p>{`Gatsby supports TypeScript by default therefore it gives me the chance to practice TypeScript, which is I believe one of the main drivers of the future of Web Development due to how convenient using TypeScript is for a team of Web Developers in JS ecosphere`}</p>
    <p>{`The same goes for React and GraphQL, because I believe those will be a big part of the web in the future if it has not already. And one of the things we really want to do as developers if we want to be more likely to be paid handsomely is to follow the trend.`}</p>
    <p>{`There is a point in my life where I believe that JavaScript will be the future of web development, because there are viable technologies you can use in both the backend and frontend that uses JavaScript (Node, React) therefore eliminating the need to hire developers with different specialization for the front-end and back-end. However, this is a naive and narrow minded view that I've had due to my lack of exposure in the industry.`}</p>
    <p>{`Imagine yourself trying to learn a technology, you bought a tutorial on Udemy for $10 or watched a youtube series. Of course to retain the things you have learned you tried to make a project using it. Good! You're on your way to have more keywords on your resume.`}</p>
    <p>{`However if you're like me you will probably feel lazy for the slightest of thought of inconvenience or worthlessness. At this point i'll probably spend a lot of time just thinking what to do, and to me this is a good excuse to continue using TypeScript and React, and learn some GraphQL`}</p>
    <h2>{`I like writing things in Markdown`}</h2>
    <p>{`Gatsby has a Markdown plugin, and that makes it very easy to create notes.`}</p>
    <p>{`I have tried a lot of applications to write my notes, and in the midst of the myriad of applications with tons of features out there, I found just keeping a good folder structure with a bunch of Markdown files very effective, it will let me focus on whats important. I usually use Notion for more complex usage, usage that requires me to create a kanban board or to make a note with an alarm, like a todo list or some sort. However I find Notion to be not really that good for notes that contains a lot of text. Because I will find myself using too much elements on it and make everything cluttered.`}</p>
    <p>{`Another list of advantages of Markdown is that it will be probably really easy to migrate it, Keep it versioned using git, and have an ability to translate it to almost anything, copy and pasting it on Notion should also work.`}</p>
    <h2>{`Because there is a lot of options to keep this website free! (almost)`}</h2>
    <p>{`Gatsby is a static site generator, therefore I can pretty much serve this website anywhere. Currently this is hosted in netlify, however, if I do so choose, I can deploy this using S3, 000webhost and others.`}</p>
    <p>{`There are many ways to create a website like this, one of the easier ways I can think of is to just create a wordpress website and maybe use 000webhost to host it. That might also make this blog more pleasing to the eyes. However due to the reasons mentioned above, I ended up doing it like this.`}</p>
    <h2>{`How about the performance? Developer experience? Security?`}</h2>
    <p>{`Perhaps those affected my decision at the end, however in an application of this scale, those ain't really that much. However if you are making a web application those might be a bigger factor to consider Gatsby for you.`}</p>
    <h3>{`TL;DR`}</h3>
    <p>{`I'm not a very reasonable person and because I want to use this opportunity to make a website that uses the near latest in web technologies, this website might be migrated to other technologies because of it, or in other words `}<strong parentName="p">{`Resume Driven Development`}</strong>{` and MarkDown.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      